<template>
  <div>
    <div class="process-schedule">
      <div class="process-schedule-left">
        <div class="process-schedule-left-header" :class="{ afterActiveBgColor: state }">
          <div v-if="item.userName">{{ item.userName }}</div>
          <div v-if="item.operateTime">{{ item.operateTime }}</div>
        </div>
        <div class="process-schedule-left-box"></div>
      </div>
      <div class="process-schedule-right">
        <div v-if="item.title">{{ item.title }}</div>
        <div v-if="item.content">{{ item.content }}</div>
        <div class="process-schedule-right-image" v-if="signName">
          <el-image
            style="width: 50px; height: 50px"
            :src="signName"
            hide-on-click-modal
            :preview-src-list="[signName]"
          />
        </div>
        <div
          class="process-schedule-right-image"
          v-if="getFileList(item).files.length > 0 || getFileList(item).images.length"
        >
          <ml-list
            v-if="getFileList(item).files.length > 0"
            fileName="fileName"
            :showDelete="false"
            showDown
            :list="getFileList(item).files"
          />
          <el-image
            style="width: 50px; height: 50px"
            v-for="result in getFileList(item).images"
            :key="result"
            :src="result.url"
            hide-on-click-modal
            :preview-src-list="getFileList(item).images.map(v => v.url)"
          />
          <!-- <ml-upload
            disabled
            accept=".png, .jpeg, .jpg"
            list-type="picture-card"
            :fileLists="getFileList(item).images"
          >
          </ml-upload> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL, FILEPREVIEWURL } from '@API'
export default {
  name: 'ProcessSchedule',
  props: {
    state: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String
    },
    signName: {
      type: String
    },
    accept: {
      type: String
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup() {
    // 区分图片与文件
    const getFileList = item => {
      const images = []
      const files = []

      if (item.attachVos && item.attachVos.length > 0) {
        item.attachVos.map(result => {
          if (['jpg', 'jpeg', 'png'].includes(result.suffix.toLowerCase())) {
            images.push({
              name: result.fileName,
              url: `${BASEURL}${FILEPREVIEWURL}/${result.filePath}`
            })
          } else {
            files.push(result)
          }
        })
      }
      return { images, files }
    }
    return { getFileList }
  }
}
</script>

<style lang="scss">
$imageWH: 60px;
.process-schedule {
  .afterActiveBgColor {
    &::after {
      content: '';
      background-color: $--color-success;
    }
  }
  display: flex;
  justify-content: center;
  &-left {
    // background-color: red;
    // height: 100%;
    width: 300px;
    // flex: 0 1;
    // min-height: 100px;
    display: flex;
    flex-direction: column;
    &-header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        height: 25px;
        line-height: 25px;
        color: $--color-text-placeholder;
        &:first-child {
          color: $--color-text-regular;
          font-size: 16px;
          font-weight: bold;
        }
      }
      &::after {
        content: '';
        width: 20px;
        height: 20px;
        background-color: $--color-text-placeholder;
        border-radius: 50%;
        position: absolute;
        right: -10px;
        top: 15px;
      }
    }
    &-box {
      width: 100%;
      flex: 1;
      border-right: 1px solid $--border-color-light;
    }
  }
  &-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 40px 40px;
    > div {
      line-height: 25px;
      color: $--color-text-placeholder;
      &:first-child {
        color: $--color-text-regular;
        font-size: 16px;
        font-weight: bold;
      }
    }
    &-image {
      padding: 5px 0;
      height: auto !important;
      .el-upload--picture-card {
        width: $imageWH;
        height: $imageWH;
        line-height: $imageWH;
        i {
          font-size: 20px;
        }
      }
      .el-upload-list--picture-card .el-upload-list__item {
        width: $imageWH;
        height: $imageWH;
        line-height: $imageWH;
      }
    }
  }
}
</style>
